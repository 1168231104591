import {
  BookCheckIcon,
  BookOpenCheckIcon,
  BotIcon,
  EarIcon,
  Globe2Icon,
  LockKeyholeIcon,
  SnowflakeIcon,
  StarsIcon,
  WholeWordIcon,
} from "lucide-react";
import { z } from "zod";

import {
  HighlightedLexicalRoot,
  createHighlightedTextNodeTree,
} from "~/components/lexical/editors/highlighter/zod";
import { type LexicalResponse } from "~/server/actions/tasks";
import { taskTypes } from "~/server/db/schema/tasks";
import { functionCall } from "~/server/gpt/prompts/functionCall";
import { ExamRoot } from "~/server/zod/exam";

import { type BaseTaskSetting } from "../components/tasks/settings-fetcher";

require("dayjs/locale/sv");

export const TaskTypeEnum = z.enum(taskTypes);
export type TaskTypeEnum = z.infer<typeof TaskTypeEnum>;

export const WordTaskViewSchema = z.object({
  type: z.literal("WORD"),
  view: z.enum(["SPELL", "QUIZ", "EXAM"]),
  answers: z.array(
    z.object({
      id: z.string(),
      data: z.record(z.string().or(z.number()).or(z.boolean())),
    }),
  ),
});

export const Word2TaskResultSchema = z.object({
  type: z.literal("WORD2"),
  view: z.enum(["SPELL", "QUIZ", "EXAM"]),
  leftColumnIds: z.array(z.string()),
  rightColumnIds: z.array(z.string()),
  answers: z.array(
    z.object({
      id: z.string(),
      data: z.record(z.string().or(z.number()).or(z.boolean())),
    }),
  ),
});

const ClozeDragAndDropResultSchema = z.object({
  type: z.literal("CLOZE").default("CLOZE"),
  view: z.literal("DRAGANDDROP").default("DRAGANDDROP"),
  occlusions: z.array(
    z.object({
      index: z.number(),
      label: z.string(),
      completed: z.boolean(),
      tries: z.number(),
    }),
  ),
});

const ClozeSpellResultSchema = z.object({
  type: z.literal("CLOZE").default("CLOZE"),
  view: z.literal("SPELL").default("SPELL"),
  occlusions: z.array(
    z.object({
      index: z.number(),
      label: z.string(),
      completed: z.boolean(),
      wasShown: z.boolean(),
      input: z.string(),
      percentageCorrect: z.number().min(0).max(1).default(0),
      alternatives: z.array(z.string()).default([]),
    }),
  ),
});

export type DragAndDropOcclusion = z.infer<
  typeof ClozeDragAndDropResultSchema
>["occlusions"][number] & { view: "DRAGANDDROP" };
export type SpellOcclusion = z.infer<
  typeof ClozeSpellResultSchema
>["occlusions"][number] & { view: "SPELL" };
export type OcclusionResult = DragAndDropOcclusion | SpellOcclusion;

const ClozeResultSchema = z.discriminatedUnion("view", [
  ClozeDragAndDropResultSchema,
  ClozeSpellResultSchema,
]);

export const baseTaskSettings = {
  WORD: {
    resultZod: WordTaskViewSchema,
    Icon: WholeWordIcon,
    safeExamBrowser: true,
    badgeClassName:
      "bg-green-200 text-gray-800 dark:bg-green-800 dark:text-gray-300",
  },
  WORD2: {
    resultZod: Word2TaskResultSchema,
    Icon: WholeWordIcon,
    safeExamBrowser: true,
    badgeClassName:
      "bg-green-200 text-gray-800 dark:bg-green-800 dark:text-gray-300",
  },
  EXIT: {
    resultZod: z.object({
      type: z.literal("EXIT").default("EXIT"),
      questions: z.array(
        z.object({
          question: z.string().min(1),
          answer: z.string().min(1),
        }),
      ),
    }),
    Icon: StarsIcon,
    safeExamBrowser: true,
    badgeClassName:
      "bg-red-200 text-gray-800 dark:bg-red-800 dark:text-gray-300",
  },
  ICEBREAKER: {
    resultZod: z.object({
      type: z.literal("ICEBREAKER").default("ICEBREAKER"),
      questions: z.array(
        z.object({
          question: z.string().min(1),
          answer: z.string().min(1),
        }),
      ),
    }),
    Icon: SnowflakeIcon,
    safeExamBrowser: true,
    badgeClassName:
      "bg-blue-200 text-gray-800 dark:bg-blue-800 dark:text-gray-300",
  },
  AIGRADER: {
    Icon: BookCheckIcon,
    resultZod: z.object({
      type: z.literal("AIGRADER").default("AIGRADER"),
      studentText: z.string(),
      studentTextRawLexical: z.string().optional(),
      studentAccess: z.boolean().default(false),
      aiResponse: functionCall.optional(),
      lexicalResponse: z
        .object({
          summary: z.string(),
          textCommentPairs: z.array(
            z.object({
              originalStudentText: z.string(),
              lexicalStudentText: HighlightedLexicalRoot,
              comment: z.string(),
            }),
          ),
        })
        .optional(),
    }),
    safeExamBrowser: true,
    badgeClassName:
      "bg-yellow-200 text-gray-800 dark:bg-yellow-800 dark:text-gray-300",
  },
  AICHAT: {
    resultZod: z.object({
      type: z.literal("AICHAT").default("AICHAT"),
      messages: z.array(
        z.object({
          content: z.string(),
          role: z.enum(["user", "assistant", "system"]),
        }),
      ),
    }),
    Icon: BotIcon,
    safeExamBrowser: true,
    badgeClassName:
      "bg-orange-200 text-gray-800 dark:bg-orange-800 dark:text-gray-300",
  },
  DICTATION: {
    resultZod: z.object({
      type: z.literal("DICTATION").default("DICTATION"),
      text: z.string(),
    }),
    Icon: EarIcon,
    safeExamBrowser: true,
    badgeClassName: "",
  },
  CLOZE: {
    resultZod: ClozeResultSchema,
    Icon: BookOpenCheckIcon,
    safeExamBrowser: true,
    badgeClassName:
      "bg-violet-200 text-gray-800 dark:bg-violet-800 dark:text-gray-300",
  },
  EXAM: {
    resultZod: z.object({
      type: z.literal("EXAM").default("EXAM"),
      answer: ExamRoot,
      teacherComment: ExamRoot.optional(),
    }),
    Icon: LockKeyholeIcon,
    safeExamBrowser: true,
    badgeClassName: "",
  },
  SCATTERGORIES: {
    resultZod: z.object({
      type: z.literal("SCATTERGORIES").default("SCATTERGORIES"),
    }),
    Icon: Globe2Icon,
    safeExamBrowser: true,
    badgeClassName: "",
  },
} satisfies Record<TaskTypeEnum, BaseTaskSetting>;

export const ResultSchema = z.discriminatedUnion("type", [
  baseTaskSettings.WORD.resultZod,
  baseTaskSettings.WORD2.resultZod,
  baseTaskSettings.EXIT.resultZod,
  baseTaskSettings.AICHAT.resultZod,
  baseTaskSettings.ICEBREAKER.resultZod,
  baseTaskSettings.AIGRADER.resultZod,
  baseTaskSettings.DICTATION.resultZod,
]);

export type AiGraderAiResultSchema = z.infer<
  typeof baseTaskSettings.AIGRADER.resultZod
>;

export function getLexicalResponseWithDefaults(
  result: AiGraderAiResultSchema,
): LexicalResponse {
  if (!result.aiResponse) {
    throw new Error("aiResponse is required");
  }

  if (!result.lexicalResponse) {
    const summary = result.aiResponse.summary;
    function generateTextCommentPairs() {
      switch (result.aiResponse!.type) {
        case "paragraphByParagraph2":
        case "paragraphByParagraph2English":
          return result.aiResponse!.paragraphs.map((s, i) => {
            const lexical = createHighlightedTextNodeTree(s.s, "yellow");
            return {
              originalStudentText: s.s,
              lexicalStudentText: lexical,
              comment: s.c ?? "",
            };
          });
        case "paragraphByParagraph":
        case "paragraphByParagraphEnglish": {
          return result.aiResponse!.sentences.map((s, i) => {
            const lexical = createHighlightedTextNodeTree(
              s.s,
              s.ok ? "green" : "yellow",
            );
            return {
              originalStudentText: s.s,
              lexicalStudentText: lexical,
              comment: s.c ?? "",
            };
          });
        }

        case "sentenceBySentence":
        case "sentenceBySentenceEnglish": {
          return result.aiResponse!.sentences.map((s, i) => {
            const lexical = createHighlightedTextNodeTree(
              s.s,
              s.ok ? "green" : "yellow",
            );
            return {
              originalStudentText: s.s,
              lexicalStudentText: lexical,
              comment: s.c ?? "",
            };
          });
        }

        default:
          throw new Error("Unsupported type");
      }
    }
    const textCommentPairs = generateTextCommentPairs();
    return {
      summary,
      textCommentPairs,
    };
  }
  return result.lexicalResponse;
}
