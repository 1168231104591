"use client";

import { GripIcon } from "lucide-react";
import { useEffect, useMemo, useRef, useState } from "react";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { z } from "zod";
import { useStore } from "zustand";

import { Translation } from "~/components/translations/component";
import {
  type DragAndDropOcclusion,
  baseTaskSettings,
} from "~/lib/baseTaskSettings";
import { cn } from "~/lib/utils";
import { saveTaskInstanceResult } from "~/server/actions/tasks";

import { PostSubmit } from "../shared";
import { CompletedTarget } from "./completed-target";
import { useClozeTaskStore } from "./provider";
import { TurnInButton } from "./turn-in-button";

export const ClozeTaskDragAndDropView = ({
  groupId,
  taskInstanceId,
}: {
  groupId: number;
  taskInstanceId: string;
}) => {
  const Store = useClozeTaskStore();
  const { occlusions, parsedClose, guessCloze, preview } = useStore(
    Store,
    (s) => ({
      occlusions: s.occlusions,
      parsedClose: s.parsedClose,
      guessCloze: s.guessCloze,
      preview: s.preview,
    }),
  );

  const [done, setDone] = useState(false);
  const [_loading, setLoading] = useState(false);

  async function handleDone() {
    if (taskInstanceId) {
      setLoading(true);
      if (!preview) {
        await saveTaskInstanceResult({
          summary: baseTaskSettings.CLOZE.resultZod.parse({
            type: "CLOZE",
            occlusions,
            view: "DRAGANDDROP",
          }),
          taskInstanceId,
          groupId,
        });
      }
      setDone(true);
      setLoading(false);
    }
  }

  useEffect(() => {
    const done = occlusions.every((o) => o.completed);
    if (done) {
      void handleDone();
    }
  }, [occlusions]);

  const sortedOcclusions = useMemo(() => {
    return occlusions.toSorted((a, b) => a.label.localeCompare(b.label));
  }, [occlusions]);

  // I don't really like this, but it's the easiest way to keep track of
  // which occlusions have been used.
  const usedIndexes: number[] = [];

  return (
    <DndProvider backend={HTML5Backend}>
      <div className="md:grid md:grid-cols-3 md:gap-4">
        <div className="md:col-span-2">
          <div className="bg-background overflow-hidden rounded-xl p-4 shadow-lg">
            {parsedClose.root.children.map((paragraph, i) => {
              return (
                <div key={i} className="whitespace-break-spaces py-1">
                  {paragraph.children.map((child, j) => {
                    if (child.type === "occluded") {
                      const occlusion = occlusions.find(
                        (occlusion) =>
                          occlusion.label === child.text &&
                          !usedIndexes.includes(occlusion.index),
                      );
                      if (!occlusion) {
                        return child.text;
                      }
                      usedIndexes.push(occlusion.index);
                      if (occlusion.view !== "DRAGANDDROP") {
                        return null;
                      }
                      if (occlusion.completed) {
                        return <CompletedTarget key={j} text={child.text} ok />;
                      }
                      if (done && !occlusion.completed) {
                        return (
                          <CompletedTarget
                            key={j}
                            text={occlusion.label}
                            ok={false}
                          />
                        );
                      }
                      return (
                        <DropTarget
                          key={j}
                          occlusion={occlusion}
                          onDrop={(item) => {
                            guessCloze(
                              occlusion.index,
                              (item as z.infer<typeof Item>).label,
                            );
                          }}
                        />
                      );
                    }
                    if (child.type === "text") {
                      return child.text;
                    }
                    return null;
                  })}
                </div>
              );
            })}
          </div>
        </div>
        <div className="flex flex-col gap-4 pt-4">
          {done ? (
            <PostSubmit groupId={groupId} preview={preview} taskType="CLOZE" />
          ) : (
            <>
              <div>
                <TurnInButton
                  loading={_loading}
                  done={done}
                  preview={preview}
                  handleDone={handleDone}
                />
              </div>
              <div>
                <p className="text-muted-foreground">
                  <Translation id="app.elev.groupid.uppgift.taskinstanceid.task-cloze.available-words">
                    Tillgängliga ord
                  </Translation>
                  :
                </p>
                <div className="flex flex-col gap-2">
                  {sortedOcclusions
                    .filter((o) => !o.completed)
                    .map((occlusion) => {
                      return (
                        <Box
                          key={occlusion.index}
                          label={occlusion.label}
                          occludedIndex={occlusion.index}
                        />
                      );
                    })}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </DndProvider>
  );
};

const type = "occluded";

interface DropTargetProps {
  onDrop: (item: unknown) => void;
  occlusion: DragAndDropOcclusion;
}

function DropTarget({ onDrop, occlusion }: DropTargetProps) {
  const [{ isOver, canDrop }, drop] = useDrop({
    accept: [type],
    drop: onDrop,
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  const [error, setError] = useState(false);
  const lastTries = useRef(0);

  useEffect(() => {
    if (occlusion) {
      if (occlusion.tries > lastTries.current) {
        setError(true);
        setTimeout(() => {
          setError(false);
        }, 1500);
      } else {
        setError(false);
      }
      lastTries.current = occlusion.tries;
    }
  }, [occlusion]);

  if (!occlusion) return null;

  const isActive = isOver && canDrop;
  let extraClassName = "bg-primary-foreground";
  if (isActive) {
    extraClassName = "bg-primary";
  } else if (canDrop) {
    extraClassName = "bg-secondary";
  }

  return (
    <div
      ref={drop}
      className={cn(
        "inline-block w-32 border-2 p-1 transition-colors",
        extraClassName,
        {
          "border-red-500": error,
          "bg-red-300": error,
          "animate-pulse": error,
        },
      )}
    >
      &nbsp;
    </div>
  );
}

const Item = z.object({
  label: z.string(),
  occludedIndex: z.number(),
});

type BoxProps = z.infer<typeof Item>;

function Box({ label, occludedIndex }: BoxProps) {
  const [{ opacity }, drag] = useDrag(
    () => ({
      type,
      item: {
        label,
        occludedIndex,
      },
      collect: (monitor) => ({
        opacity: monitor.isDragging() ? 0.4 : 1,
      }),
    }),
    [label, type],
  );

  return (
    <div
      ref={drag}
      style={{ opacity }}
      className="bg-background flex w-full cursor-grab flex-row items-center justify-center rounded border border-dotted"
    >
      <GripIcon className="m-2 h-4 w-4" />
      <div className="flex h-full w-full flex-row items-center text-center">
        <p className="w-full">{label}</p>
      </div>
    </div>
  );
}
