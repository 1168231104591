"use client";

import FuzzySet from "fuzzyset";
import {
  type ReactNode,
  createContext,
  useContext,
  useEffect,
  useRef,
} from "react";
import { createStore } from "zustand";

import {
  type DragAndDropOcclusion,
  type OcclusionResult,
  type SpellOcclusion,
} from "~/lib/baseTaskSettings";
import { logger } from "~/lib/logger";
import { ClozeRoot, type Occlusion } from "~/server/zod/cloze";
import {
  type ClozeInsertSchema,
  type ClozeSelectSchema,
} from "~/server/zod/tasks";

import { type TaskProps } from "../shared";

type ClozeTaskSchema = ClozeInsertSchema | ClozeSelectSchema;
export type ClozeViewType = "DRAGANDDROP" | "SPELL";

interface ClozeTaskProps {
  task: ClozeTaskSchema;
  parsedClose: ClozeRoot;
  view: ClozeViewType;
}

export interface ClozeTaskState extends ClozeTaskProps {
  occlusions: OcclusionResult[];
  resetStore: () => void;
  preview: boolean;
  guessCloze: (targetOcclusionIndex: number, guess: string) => void;
  setView: (newView: ClozeViewType) => void;
}

interface ClozeTaskContext {
  preview?: boolean;
  task: ClozeTaskSchema;
  view: ClozeViewType;
}

type ClozeTaskStore = ReturnType<typeof createClozeTaskStore>;
const createClozeTaskStore = (ctx: ClozeTaskContext) => {
  const parsedClose = ClozeRoot.parse(
    typeof ctx.task.rawLexical === "string"
      ? JSON.parse(ctx.task.rawLexical)
      : ctx.task.rawLexical,
  );

  function generateOcclusions(view: ClozeViewType) {
    const _occlusions = parsedClose.root.children.flatMap((paragraph) =>
      paragraph.children.filter((child) => child.type === "occluded"),
    );

    return _occlusions.map((child: Occlusion, i): OcclusionResult => {
      const baseOcclusion = {
        index: i,
        label: child.text,
        completed: false,
      };
      return view === "DRAGANDDROP"
        ? { ...baseOcclusion, tries: 0, view: "DRAGANDDROP" }
        : {
            ...baseOcclusion,
            wasShown: false,
            input: "",
            percentageCorrect: 0,
            view: "SPELL",
            alternatives: child.alternatives,
          };
    });
  }

  const DEFAULT_PROPS: ClozeTaskProps = {
    task: ctx.task,
    parsedClose,
    view: ctx.view,
  };
  return createStore<ClozeTaskState>()((set) => ({
    ...DEFAULT_PROPS,
    occlusions: generateOcclusions(ctx.view),
    task: ctx.task,
    preview: ctx.preview ?? false,
    resetStore: () => set((state) => ({ ...DEFAULT_PROPS, _task: state.task })),
    setView: (newView: ClozeViewType) =>
      set((state) => ({
        ...state,
        view: newView,
        occlusions: generateOcclusions(newView),
      })),
    guessCloze: (targetOcclusionIndex, guess) =>
      set((state) => {
        const occlusion = state.occlusions[targetOcclusionIndex];
        if (!occlusion) {
          logger.debug("No occlusion found");
          return state;
        }
        if (occlusion.view !== state.view) {
          logger.debug(
            {
              occlusionView: occlusion.view,
              stateView: state.view,
            },
            "View mismatch",
          );
          return state;
        }
        if (occlusion.view === "SPELL") {
          const answers = [occlusion.label, ...occlusion.alternatives].map(
            (w) => w.trim(),
          );
          const fuzzy = FuzzySet(answers).get(guess, undefined, 0);
          const percentageCorrect = fuzzy?.[0]?.[0] ?? 0;
          const completed = percentageCorrect === 1;
          return {
            ...state,
            occlusions: state.occlusions.map((occlusion) => {
              if (occlusion.index === targetOcclusionIndex) {
                const next: SpellOcclusion = {
                  ...(occlusion as SpellOcclusion),
                  completed,
                  percentageCorrect,
                  input: guess,
                };
                return next;
              }
              return occlusion;
            }),
          };
        }
        if (occlusion.view === "DRAGANDDROP") {
          const completed = occlusion.label === guess;
          const tries = occlusion.tries + 1;
          const occlusions = state.occlusions.map((occlusion) => {
            if (occlusion.index === targetOcclusionIndex) {
              const next: DragAndDropOcclusion = {
                ...(occlusion as DragAndDropOcclusion),
                completed,
                tries,
              };
              return next;
            }
            return occlusion;
          });
          return { ...state, occlusions };
        }
        throw new Error("Invalid view");
      }),
  }));
};

export type Props = TaskProps<ClozeTaskSchema>;

export const ClozeTaskContext = createContext<ClozeTaskStore | null>(null);

export function Provider({
  children,
  ctx,
}: {
  children: ReactNode;
  ctx: ClozeTaskContext;
}) {
  const store = useRef(createClozeTaskStore(ctx)).current;
  useEffect(() => {
    store.getState().setView(ctx.view);
  }, [ctx.view, store]);
  return (
    <ClozeTaskContext.Provider value={store}>
      {children}
    </ClozeTaskContext.Provider>
  );
}

export function useClozeTaskStore() {
  const Store = useContext(ClozeTaskContext);
  if (!Store) throw new Error("Missing ClozeTaskContext.Provider in the tree");
  return Store;
}
