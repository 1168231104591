"use client";

import { cn } from "~/lib/utils";

interface CompletedTargetProps {
  text: string;
  ok: boolean;
}

export function CompletedTarget({ text, ok }: CompletedTargetProps) {
  return (
    <span
      className={cn(
        "rounded border border-green-500 bg-green-200 p-1 text-black",
        ok ? "border-green-500" : "border-red-500 bg-red-200",
      )}
    >
      {text}
    </span>
  );
}
