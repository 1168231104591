"use client";

import { LoadingButton } from "~/components/loading-button";
import { Translation } from "~/components/translations/component";

interface TurnInButtonProps {
  done: boolean;
  loading: boolean;
  preview: boolean;
  handleDone: () => void;
}
export function TurnInButton({
  done,
  loading,
  preview,
  handleDone,
}: TurnInButtonProps) {
  return (
    <LoadingButton
      isLoading={loading}
      onClick={handleDone}
      disabled={done || preview}
    >
      <Translation id="common.turn-in">Lämna in</Translation>
    </LoadingButton>
  );
}
